import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
      <div className="text-center mb-4">
        <Link to="/" className="btn btn-primary">
          Back To Game
        </Link>
      </div>

      <div className="card">
        <div className="card-body">
          <h1 className="card-title text-center mb-4">Privacy Policy</h1>
          <p>
            Welcome to <strong>Tic vs Com</strong>. Your privacy is important to us, and this policy
            is designed to inform you about how we handle any information related to your use of our website.
          </p>

          <h2>Information Collection</h2>
          <p>
            We do not collect any personal data from our users. You can browse our website without providing
            any personal information such as names, email addresses, or other details.
          </p>

          <h2>Use of Cookies</h2>
          <p>We do not use cookies or any tracking technologies to gather information about users.</p>

          <h2>Displaying Ads</h2>
          <p>
            Our website may display third-party advertisements, including Google Ads.
          </p>

          <h3>Google Ads</h3>
          <p>
            We use <strong>Google AdSense</strong> to display advertisements on our website. Google, as a
            third-party vendor, uses cookies to serve ads based on users' prior visits to this site and other websites
            on the internet. Google's use of advertising cookies enables it and its partners to serve ads based on user interests.
          </p>
          <ul>
            <li>
              You can opt-out of personalized advertising by visiting the{" "}
              <a href="https://adssettings.google.com/" target="_blank" rel="noopener noreferrer">
                Google Ads Settings
              </a>.
            </li>
            <li>
              To learn more about how Google uses cookies for ads and the data they collect, please refer to the{" "}
              <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">
                Google Privacy & Terms page
              </a>.
            </li>
          </ul>
          <p>
            We do not have access to or control over the cookies used by third-party advertisers, including Google, and their
            use is governed by their privacy policies.
          </p>

          <h2>No Copyright</h2>
          <p>
            The content and materials on <strong>Tic vs Com</strong> are provided without any copyright restrictions. You are free
            to use, share, or modify any content on this site.
          </p>

          <h2>External Links</h2>
          <p>
            Our site may contain links to other websites, such as other projects we are involved in. We are not responsible
            for the privacy practices of these other websites. Please review the privacy policies of these external sites
            when visiting them.
          </p>

          <h2>Updates to This Policy</h2>
          <p>
            We may update this privacy policy from time to time. Any changes will be reflected on this page, and we encourage
            you to check back regularly for updates.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about our privacy policy, please feel free to contact us via our contact page.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
