import React, { useState, useEffect } from 'react';
import Footer from './Footer';


const Home = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const winner = calculateWinner(board);

  useEffect(() => {
    if (!isXNext && !winner) {
      makeComputerMove();
    }
  }, [isXNext, winner]);

  const handleClick = (index) => {
    if (board[index] || winner || !isXNext) return;
    const newBoard = [...board];
    newBoard[index] = 'X';
    setBoard(newBoard);
    setIsXNext(false);
  };

  const makeComputerMove = () => {
    const emptySquares = board.map((val, idx) => (val === null ? idx : null)).filter(val => val !== null);
    if (emptySquares.length === 0 || winner) return;
    
    const randomIndex = emptySquares[Math.floor(Math.random() * emptySquares.length)];
    const newBoard = [...board];
    newBoard[randomIndex] = 'O';
    setTimeout(() => {
      setBoard(newBoard);
      setIsXNext(true);
    }, 500); // slight delay for a better user experience
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
  };

  const renderSquare = (index) => {
    return (
      <button className="square btn" onClick={() => handleClick(index)}>
        {board[index] === 'X' && <i className="fas fa-times fa-3x text-primary"></i>}
        {board[index] === 'O' && <i className="far fa-circle fa-3x text-warning"></i>}
      </button>
    );
  };

  return (
    <>
     <div className="container d-flex justify-content-center align-items-center py-3  ">
      <div className='d-flex flex-column justify-content-center align-items-center'> 
      <h1 className="text-center font_size_title mb-3">Tic Tac Toe <span className='text-danger'>vs</span> Computer</h1>
      <div className="game">
       
        <div className="board mx-auto">
          <div className="row">
            {renderSquare(0)}
            {renderSquare(1)}
            {renderSquare(2)}
          </div>
          <div className="row">
            {renderSquare(3)}
            {renderSquare(4)}
            {renderSquare(5)}
          </div>
          <div className="row">
            {renderSquare(6)}
            {renderSquare(7)}
            {renderSquare(8)}
          </div>
        </div>
        <div className="info text-center mt-4">
          {winner ? (
            <h2 className="text-success">Winner: {winner}</h2>
          ) : (
            <h2 className="text-danger">Next Player: {isXNext ? 'X' : 'O'}</h2>
          )}
          <button className="btn button_resetgame mt-3" onClick={resetGame}>
            Reset Game
          </button>
        </div>
      </div>
        
      </div>
    
    </div>
    <div>
        <Footer/>
    </div>
    
    </>
   
  );
};

const calculateWinner = (board) => {
  const lines = [
    [0, 1, 2], [3, 4, 5], [6, 7, 8],
    [0, 3, 6], [1, 4, 7], [2, 5, 8],
    [0, 4, 8], [2, 4, 6]
  ];

  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (board[a] && board[a] === board[b] && board[a] === board[c]) {
      return board[a];
    }
  }
  return null;
};

export default Home;
