import React from 'react'
import './App.css';
import Home from './component/Home';
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './component/PrivacyPolicy';




const App = () => {
  return (
    <>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/Policy' element={<PrivacyPolicy/>}/>
    </Routes>
    
    
 
    
    </>
   
  )
}

export default App
