import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='bg-dark text-white py-4'>
      <div className='container'>
        <div className='row'>
          {/* Left section */}
          <div className='col-md-6'>
            <p>&copy; 2024 Tic vs Com. All rights reserved.</p>
          </div>
          
          {/* Right section with privacy policy link */}
          <div className='col-md-6 text-md-end'>
            <Link to="/Policy" className='text-white'>
              Privacy Policy
            </Link>
          </div>
        </div>

        {/* Additional section with other websites */}
        <div className='row mt-4'>
          <div className='col text-center'>
            <h5>Explore My Other Websites</h5>
            <ul className='list-unstyled'>
              <li>
                <a href="https://dorean.solutions/" className='text-white' target="_blank" rel="noopener noreferrer">
                  dorean.solutions
                </a>
              </li>
              <li>
                <a href="https://imagedynamics.online/" className='text-white' target="_blank" rel="noopener noreferrer">
                  imagedynamics.online
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Social media section */}
    
      </div>
    </footer>
  );
};

export default Footer;
